import React, { useState } from 'react';
import NavItem from './NavItem';
import Shop from './pages/Shop';

const Nav = ({pages}) => {

    const filterPages = pages.filter(({nav}) => nav !== false);
    return (
        <nav style={{background: '#F2F2F2'}}>
            <div className="container">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        {filterPages.map((nav, index) => <NavItem key={index} {...nav} />)}
                    </div>
                    <div>
                        <NavItem title="Ringkøbing Cykelklub" active={false} onClick={() => console.log('forening')} style={{fontStyle: 'italic'}} />
                    </div>
                </div>
                
            </div>
        </nav>
    )
}

export default Nav;