import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { formatNumber } from '../helpers';
import { CartContext } from '../App';

const Header = ({points, setPageTitle}) => {
    const [cart] = useContext(CartContext);
    const values = Object.values(cart);
    const cartCount = values.length ? values.reduce((acc, {count}) => ({count: acc.count + count})).count : 0;

    return (
        <header>
            <div className="container">
                <div style={{ display: 'flex', alignItems: 'center', height: 100, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 400, cursor: 'pointer'}} onClick={() => setPageTitle('Dashboard')}>
                        <h1 style={{ fontStyle: 'italic', color: '#0074bb', fontWeight: 700, marginBottom: 0 }}>Klubpartner</h1>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: 12, fontStyle: 'italic', color: '#666' }}>- En del af </span>
                            <img src="/logo.svg" alt="Cykelpartner" style={{ width: 100, marginLeft: 5 }} />
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <div className="points">
                                <FontAwesomeIcon icon={faCoins} />
                                <span className="points-label">{formatNumber(points)} klubpoint</span>
                            </div>
                            <div style={{textAlign: 'right'}}>
                                Til rådighed
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-cart" onClick={() => setPageTitle('Cart')}>
                                <FontAwesomeIcon icon={faShoppingCart} style={{fontSize: 20}} />
                                {cartCount ? <label className="cart-badge">{cartCount}</label> : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;