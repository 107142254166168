import React, { useContext } from 'react';
import { ButtonToolbar, Button, InputGroup, FormControl, ButtonGroup } from 'react-bootstrap';
import { CartContext } from '../App';

const CartItem = ({product, count, removeProduct, reduceProduct}) => {
    const dispatch = useContext(CartContext)[1];

    return (
        <div className="cart-item card">
            <div className="card-body" style={{display: 'flex'}}>
                <img src={product.imgUrl} style={{height: '100%', width: 200}}/>
                <div style={{flex: 1}}>
                    <h4>{product.title}</h4>
                    <p>{product.description}</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <InputGroup style={{width: 120, justifySelf: 'flex-end'}}>
                            <InputGroup.Prepend>
                                <Button variant="secondary" onClick={() => dispatch({ type: 'REDUCE_PRODUCT',  productId: product.id })}>-</Button>
                            </InputGroup.Prepend>
                            <FormControl
                                type="text"
                                value={count}
                                style={{textAlign: 'center'}}
                                onChange={e => dispatch({ type: 'UPDATE_PRODUCT_COUNT', productId: product.id, count: e.target.value})}
                            />
                            <InputGroup.Append>
                                <Button variant="secondary" onClick={() => dispatch({ type: 'ADD_PRODUCT', product })}>+</Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <Button className="remove-product-btn" variant="default" onClick={() => dispatch({ type: 'REMOVE_PRODUCT',  productId: product.id })}>Fjern vare</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem;