import React, { useContext } from 'react';
import { CartContext } from '../../App';
import { formatNumber } from '../../helpers';
import CartItem from '../CartItem';
import Swal from 'sweetalert2';
import { Button, ButtonToolbar, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins } from '@fortawesome/free-solid-svg-icons'

const Cart = ({setPageTitle, points, setPoints}) => {
    const [cart, dispatch] = useContext(CartContext);
    const entries = Object.entries(cart);

    const total = entries.length ? Object.values(cart).map(({count, product: {price}}) => count * price).reduce((acc, val) => acc + val) : 0;

    const handleBuy = () => {
        if (total > points) {
            Swal.fire('Købet blev ikke gennemført', 'Ikke nok point på kontoen til at foretage dette køb', 'error'); 
            return;
        }
        Swal.fire('Købet blev gennemført', 'Produkterne er sendt til afsendelse', 'success'); 
        setPoints(points - total);
        dispatch({ type: 'RESET' });
        setPageTitle('Dashboard');
    }

    return (
        <div className="container">
            <div style={{ margin: '15px 0' }}>
                <h2>Indkøbskurv</h2>
                <p>Gennemse din indkøbskurv og gå til kassen hvis du er klar.</p>
            </div>
            
            {total > points ? (
                <Alert variant="danger">
                    Du har ikke nok point på kontoen til at foretage dette køb
                </Alert>
            ) : ''}
            <div className="cart">
                {entries.length ? (
                    <>
                        {entries.map(([id, productInfo]) => (
                            <CartItem 
                                {...productInfo} 
                                removeProduct={productId => dispatch({ type: 'REMOVE_PRODUCT', productId })} 
                                reduceProduct={productId => dispatch({ type: 'REDUCE_PRODUCT', productId })}
                            />
                        ))}
                         <ButtonToolbar>
                            <Button onClick={handleBuy} variant="primary" size="md">Køb produkter</Button>
                            <Button onClick={() => setPageTitle('Shop')}  variant="secondary">Tilbage til shoppen</Button>
                            <div style={{marginTop: 7}}>
                                <p style={{margin: 0}}>Total pris i point:<strong> <FontAwesomeIcon icon={faCoins} /> {formatNumber(total)}</strong></p>
                            </div>
                        </ButtonToolbar>
                    </>
                   
                ) : (
                    <div>
                        <p className="text-muted font-italic">Ingen produkter i kurven</p>
                        <Button onClick={() => setPageTitle('Shop')}  variant="secondary">Tilbage til shoppen</Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Cart;