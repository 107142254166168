import React from 'react';
import ShopItem from '../ShopItem';

const Events = () => {
    return (
        <div className="container">
            <div style={{ margin: '15px 0' }}>
                <h2>Begivenheder</h2>
                <p>Administrer de næste begivenheder for klubben. Planlæg næste træning for dine medlemmer eller opret et special event. Det er op til dig</p>
            </div>
            <div className="shop-grid">   
                <ShopItem 
                    title="Landevejstræning"
                    description="Hver torsdag kl. 19:00"
                    imgUrl="/bike.jpeg"
                    button={{title: 'Rediger'}}
                />
                <ShopItem 
                    title="Lang cykeltur til Silkeborg - tur/retur"
                    description="På lørdag kl. 11:00"
                    imgUrl="/woods.jpg"
                    button={{title: 'Rediger'}}
                />
            </div>
        </div>
    )
}

export default Events;