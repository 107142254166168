import React, { useState, useReducer } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header';
import Nav from './components/Nav';
import Dashboard from './components/pages/Dashboard';
import Shop from './components/pages/Shop';
import Cart from './components/pages/Cart';
import Members from './components/pages/Members';
import Events from './components/pages/Events';

export const ProductsContext = React.createContext();
export const CartContext = React.createContext();

const products = [
    {
        id: 1,
        title: 'Shimano Sora FC-R3030 - Triple kranksæt - 50/39/30 tands 175 mm pedalarm',
        description: 'FC-R3030 er et Shimano Sora kranksæt, som passer til gearkombinationen 3 x 9 gear. Kranksættet kan udelukkende anvendes sammen med 3 klinger...',
        imgUrl: '/products/1.jpg',
        price: 6599,
    },
    {
        id: 2,
        title: 'Diadora Giro Windbreaker - Cykeljakke - Gul/sort',
        description: 'Diadora Giro Windbreaker er en vindtæt cykeljakke, der med sin lave vægt er perfekt at pakke i tasken når du tager på længere ture...',
        imgUrl: '/products/2.jpg',
        price: 4999,
    },
    {
        id: 3,
        title: 'Giro Trinity Sport ROC - Cykelhjelm - Str. 53-61 cm - Sort/Hvid',
        description: 'Trinity Sport ROC er en cykelhjelm til hverdag, sport og motion med detaljer fra topkonkurrence hjelmene. Hjelmen er konstrueret med In-Mold teknologi...',
        imgUrl: '/products/3.jpg',
        price: 3999,
    },
    {
        id: 4,
        title: 'Shimano Sora FC-R3030 - Triple kranksæt - 50/39/30 tands 175 mm pedalarm',
        description: 'FC-R3030 er et Shimano Sora kranksæt, som passer til gearkombinationen 3 x 9 gear. Kranksættet kan udelukkende anvendes sammen med 3 klinger...',
        imgUrl: '/products/1.jpg',
        price: 3999,
    },
];

const cartReducer = (state, action) => {
    switch(action.type) {
        case 'ADD_PRODUCT':
            var { id } = action.product;

            

            // Update count of existing material
            if (state.hasOwnProperty(id)) {
                console.log('in object');
                console.log({state});
                return { 
                    ...state, 
                    [id]: { 
                        ...state[id], 
                        count: state[id].count + 1 
                    } 
                };
            }
            
            // New material in the cart
            return { 
                ...state, 
                [id]: {
                    product: action.product, 
                    count: 1
                } 
            };
        case 'REMOVE_PRODUCT':
            var newState = { ...state };
            delete newState[action.productId];
            return newState;

        case 'REDUCE_PRODUCT':
            var id = action.productId;

            // Update count of existing material
            if (id in state && state[id].count > 1) {
                return {
                    ...state,
                    [id]: {
                        ...state[id],
                        count: state[id].count - 1,
                    }
                }
            }
            
            // Remove the product completely
            var newState = { ...state };
            delete newState[action.productId];
            return newState;
            

        case 'RESET':
            return {};

        case 'UPDATE_PRODUCT_COUNT':
            return {
                ...state,
                [action.productId]: {
                    ...state[action.productId],
                    count: parseInt(action.count),
                }
            }

        default:
            return state;
    }
} 

const App = () => {
    const [pageTitle, setPageTitle] = useState('Dashboard');
    const [points, setPoints] = useState(30000);

    const pages = [
        {
            title: 'Dashboard',
            onClick: () => setPageTitle('Dashboard'),
            active: pageTitle === 'Dashboard'
        },
        {
            title: 'Shop',
            onClick: () => setPageTitle('Shop'),
            active: pageTitle === 'Shop'
        },
        {
            title: 'Cart',
            nav: false,
            onClick: () => setPageTitle('Cart'),
            active: pageTitle === 'Cart'
        },
        {
            title: 'Medlemmer',
            onClick: () => setPageTitle('Medlemmer'),
            active: pageTitle === 'Medlemmer'
        },
        {
            title: 'Begivenheder',
            onClick: () => setPageTitle('Begivenheder'),
            active: pageTitle === 'Begivenheder'
        },
    ]

    let page = '';
    switch (pageTitle) {
        case 'Dashboard':
            page = <Dashboard setPageTitle={setPageTitle} points={[points, setPoints]}/>;
            break;

        case 'Shop':
            page = <Shop setPageTitle={setPageTitle} />;
            break;

        case 'Cart':
            page = <Cart points={points} setPoints={setPoints} setPageTitle={setPageTitle} />
            break;

        case 'Medlemmer':
            page = <Members />
            break;
        
        case 'Begivenheder':
            page = <Events />

        default:
            break;
    }

    return (
        <div className="App">
            <ProductsContext.Provider value={products}>
                <CartContext.Provider value={ useReducer(cartReducer, {}) } >
                    <Header points={points} setPageTitle={setPageTitle} />
                    <Nav pages={pages} />
                    {page}
                </CartContext.Provider>
            </ProductsContext.Provider>
        </div>
    );
}

export default App;
