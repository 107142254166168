import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faCartPlus } from '@fortawesome/free-solid-svg-icons'

const ShopItem = ({ title, description, button = false, imgUrl, price = false }) => (
    <div className="shop-item">
        <div className="card" style={{ height: '100%' }}>
            <img src={imgUrl} alt={title} className="card-img-top" style={{ objectFit: 'cover', height: 150 }} />
            <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{description}</p>
                <div style={{ flex: 1, flexDirection: 'column'}}>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {button ? (
                        <button className="btn btn-primary" onClick={button.onClick}>{price ? <FontAwesomeIcon icon={faCartPlus} /> : ''} {button.title}</button>
                    ) : ''}
                    {price ? (
                        <span className="text-muted"><FontAwesomeIcon icon={faCoins} /> {price}</span>
                    ) : ''}
                </div>
            </div>
        </div>
    </div>

)

export default ShopItem;