import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import SweetAlert from 'sweetalert-react';
import ShopItem from '../ShopItem';
import Swal from 'sweetalert2';

const Dashboard = ({ setPageTitle, points: [points, setPoints] }) => {
    const [show, setShow] = useState(false);
    const [payOut, setPayOut] = useState('');
    const [showError, setShowError] = useState(false);

    const handlePointsChange = e => {
        const newPayOutPoints = e.target.value;
        if (parseInt(newPayOutPoints) > points) {
            setShowError(true);
            return;
        }

        setShowError(false);
        setPayOut(newPayOutPoints);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setShow(false);
        setPoints(points - payOut);
        setPayOut('');
        setTimeout(() => Swal.fire('Succes!', 'Dine penge vil være på din konto i løbet af et par dage.', 'success'), 250);
    }

    return (
        <>
            <div className="container">
                <div style={{ margin: '15px 0' }}>
                    <h2>Dashboard</h2>
                    <p>Her kan I vælge hvad I ønsker at gøre med dine klubpoint. Enten kan I få udbetalt pointene i kontanter, dog er det kun muligt at få 85 % af dine point udbetalt i kontanter. </p>
                </div>
                <div className="shop-grid">
                    <ShopItem
                        title="Få klubpoint udbetalt"
                        description="Få 85 % af klubpointene udbetalt til brug i din klub eller forening"
                        imgUrl="/money.jpg"
                        button={{ title: 'Udbetal nu', onClick: () => setShow(true) }}
                    />
                    <ShopItem
                        title="Køb i shoppen"
                        description="Brug jeres klubpoint til at indkøbe varer til jeres forening her"
                        imgUrl="/bike.jpeg"
                        button={{ title: 'Gå til shoppen', onClick: () => setPageTitle('Shop') }}
                    />
                </div>
            </div>
            <Modal size="md" show={show} onHide={() => setShow(false)}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Udbetal penge</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Vi har gemt dine kontooplysninger for dig. <a href="#">Rediger dit kontonummer her</a> </p>
                        

                        <Form.Group controlId="formPoints">
                            <Form.Label>Antal point til udbetaling</Form.Label>
                            <Form.Control isInvalid={showError} type="number" placeholder="Indtast antal point" value={payOut} onChange={handlePointsChange} />
                            <Form.Control.Feedback type="invalid">
                                Du kan ikke få udbetalt flere points end du har på kontoen
                            </Form.Control.Feedback>
                            <Form.Text className="text-muted">
                                Du har nu <strong>{points - payOut}</strong> points til rådighed
                            </Form.Text>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="button" variant="secondary" onClick={() => setShow(false)}>
                            Annuller
                    </Button>
                        <Button type="submit" variant="primary" onClick={() => setShow(false)}>
                            Udfør
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default Dashboard;