import React from 'react';
import {Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencilAlt, faEye } from '@fortawesome/free-solid-svg-icons'

const Members = () => {
    const members = [
        {
            id: 1,
            name: 'Nina Kristiansen',
            created: '12/05/2003',
            paid: true,
        },
        {
            id: 2,
            name: 'Martin Lykke Bach',
            created: '04/06/2013',
            paid: false,
        },
        {
            id: 3,
            name: 'Emil Dammark Øllgaard Jensen',
            created: '11/07/2016',
            paid: true,
        },
        {
            id: 4,
            name: 'Jonathan Friis',
            created: '26/03/2019',
            paid: true,
        },
        {
            id: 5,
            name: 'Anders Møller Schmidt',
            created: '08/04/2017',
            paid: true,
        },
        {
            id: 6,
            name: 'Asger Nohns Jensen',
            created: '22/08/2018',
            paid: true,
        },
    ]

    return (
        <div className="container">
            <div style={{ margin: '15px 0' }}>
                <h2>Medlemmer</h2>
                <p>En oversigt over alle medlemmer i din klub. Her er det muligt at se om alle har betalt kontigent, hvem der er tilmeldt osv.</p>
            </div>
            <div>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Medlemsnr.</th>
                            <th>Navn</th>
                            <th>Dato tilmeldt</th>
                            <th>Betalt</th>
                            <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map(({id, paid, created, name}, index) => (
                            <tr>
                                <td>{id}</td>
                                <td>{name}</td>
                                <td>{created}</td>
                                <td>{paid ? 'Ja' : 'Nej'}</td>
                                <td>
                                    <FontAwesomeIcon icon={faEye} />&nbsp;&nbsp;&nbsp; 
                                    <FontAwesomeIcon icon={faPencilAlt} />&nbsp;&nbsp;&nbsp; 
                                    <FontAwesomeIcon icon={faTimes} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Members;
