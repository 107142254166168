import React, { useContext } from 'react';
import ShopItem from '../ShopItem';
import { ProductsContext, CartContext } from '../../App';

const Shop = () => {
    const products = useContext(ProductsContext);
    const [cart, dispatch] = useContext(CartContext);

    return (
        <div className="container">
            <div style={{margin: '15px 0'}}>
                <h2>Shoppen</h2>
                <p>
                    Brug jeres klubpoint her i shoppen, og køb produkter til foreningen. Find alle delene fra den rigtige webshop til en bedre pris kun for din klub.
                </p>
            </div>
            <div className="shop-grid">
                {products.map((product, index) => (
                    <ShopItem
                        key={index}
                        {...product}
                        button={{ title: 'Læg i kurv', onClick: () => dispatch({ type: 'ADD_PRODUCT', product }) }}
                    />
                ))}
            </div>
        </div>
    )
}

export default Shop;